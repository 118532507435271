:root {
  --et-color-1: #1b7a47;
  --et-color-2: #ffcc2a;
}

/* body { */
/* color: white; */
/* color: var(--et-color-1) !important; */
/* font-family: "Inter"; */
/* } */

.social-links ul li {
  display: inline;
  list-style-type: none;
}

.social-links i {
  font-size: 18px;
}

.footer-social-links li {
  padding: 0 3px;
}

.footer-social-links > li > a {
  padding: 15px;
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 50%;
}

.footer-social-links > li > a:hover {
  color: red !important;
  background-color: var(--et-color-2);
  border-radius: 50%;
}

.social-links i.fa {
  padding-left: 5px;
  padding-right: 5px;
  color: var(--et-color-1);
}

li.nav-item > a,
.offcanvas-header {
  color: var(--et-color-1) !important;
}

i.fa-ellipsis {
  color: var(--et-color-1);
  font-size: 40px !important;
}

i.fa-circle-xmark {
  color: red;
  font-size: 40px !important;
}

.et-dropdown {
  color: var(--et-color-1) !important;
  background-color: whitesmoke;
}

.et-dropdown a.dropdown-item:hover {
  color: var(--et-color-2);
  background-color: var(--et-color-1);
  border-radius: 0 !important;
  text-decoration: underline;
  text-decoration-color: var(--et-color-2);
  font-weight: 600;
}

.jumbotron {
  height: 100% !important;
  background-size: cover !important;
  background-position: center center !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* .staff:hover {
  box-sizing: border-box;
  transform: scale(150%);
} */

.footer {
  color: var(--et-color-1);
  background-color: rgba(27, 122, 71, 0.3);
  padding: 10px 0;
}

.footer-contact ul {
  list-style-type: none;
  padding: 0;
}

ul p,
ul a {
  color: var(--et-color-1);
  text-decoration: none;
  margin: 0;
}

.flex {
  justify-content: space-between !important;
}

.footer-links,
.footer-links li {
  list-style-type: none;
  padding: 0;
}

.footer-quote {
  /* background-color: rgba(0, 25, 0, 0.2); */
  background-color: var(--et-color-1);
  color: white;
  padding: 20px 30px;
}

.footer-quote p {
  line-height: 1.2;
  font-size: 17px;
}

.footer a:hover {
  text-decoration: underline;
  font-weight: 700;
}

.et-btn {
  border: 0 !important;
  /* outline: 0 !important; */
  font-weight: 700 !important;
  font-size: 14px !important;
  padding: 10px 20px !important;
  border-radius: 30px !important;
  color: var(--et-color-1) !important;
  background-color: whitesmoke !important;
}

.et-btn:hover {
  background-color: var(--et-color-2) !important;
}

/* .et-link {
  color: var(--et-color-1);
  text-decoration: none;
}

.et-link:hover {} */

.fas,
.fa-solid {
  color: var(--et-color-1);
}

.service-highlight i {
  font-size: 60px;
  margin-bottom: 20px;
}

.about-service .col-md-4\ {
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  border: 1px solid transparent;
  /* transition: box-shadow 1s; */
}

.about-service .col-md-4:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* border: 1px solid var(--et-color-1); */
  transition: box-shadow 1s;
}

img.img-fluid.animate__pulse {
  animation-duration: infinite;
}

.et-about-list li {
  list-style-type: none;
}

.et-option-list > li {
  list-style-type: none;
  padding: 15px 0px;
  font-size: 20px;
}

.service-nav li,
.service-nav a {
  background-color: var(--et-color-1) !important;
  color: rgb(255, 255, 255) !important;
}

.service-nav li:hover,
.service-nav a:hover {
  background-color: var(--et-color-2) !important;
  color: var(--et-color-1) !important;
  text-decoration: underline !important;
}

.text-justify {
  text-align: justify !important;
}

div.col-md-4.p-4 {
  background-color: lightgrey;
  color: var(--et-color-1);
}

div.col-md-4.p-4:hover {
  color: var(--et-color-1);
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  transition: box-shadow 0.3s;
}

div.accordion-item:nth-child(1) > h2:nth-child(1) > button:nth-child(1),
div.accordion-item:nth-child(2) > h2:nth-child(1) > button:nth-child(1),
div.accordion-item:nth-child(3) > h2:nth-child(1) > button:nth-child(1) {
  color: var(--et-color-1);
  background-color: white;
}

/* Carousel */
.slide-img {
  height: 100% !important;
}

.carousel-caption {
  background-color: rgba(0, 0, 0, 0.63);
  padding: 30px;
}

.fas.control {
  color: white !important;
}

@media (min-width: 768px) {
  .fas.control {
    font-size: 50px !important;
  }
}

.modal-dark .modal-content {
  background-color: #222 !important; /* Dark background color */
  /* color: #fff !important; /* Light text color */
  border: 0 !important; /* Remove borders */
}

@media (max-width: 768px) {
  .mb-sm-3 {
    margin-bottom: 10px !important;
  }
}

.developer-stamp {
  font-size: 25px !important;
}

.developer-stamp li {
  display: inline !important;
  padding: 0 5px !important;
}

.developer-stamp .fa-brands {
  background-color: #ffffff !important;
  padding: 5px;
  border-radius: 50% !important;
}

.developer-stamp .fa-brands:hover {
  background-color: var(--et-color-2) !important;
  padding: 5px;
}